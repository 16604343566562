/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { incidentLogConfigurationCreateConfiguration } from '../fn/incident-log-configuration/incident-log-configuration-create-configuration';
import { IncidentLogConfigurationCreateConfiguration$Params } from '../fn/incident-log-configuration/incident-log-configuration-create-configuration';
import { IncidentLogConfigurationDto } from '../models/incident-log-configuration-dto';
import { incidentLogConfigurationGetConfiguration } from '../fn/incident-log-configuration/incident-log-configuration-get-configuration';
import { IncidentLogConfigurationGetConfiguration$Params } from '../fn/incident-log-configuration/incident-log-configuration-get-configuration';
import { incidentLogConfigurationUpdatePaperConsignmentConfiguration } from '../fn/incident-log-configuration/incident-log-configuration-update-paper-consignment-configuration';
import { IncidentLogConfigurationUpdatePaperConsignmentConfiguration$Params } from '../fn/incident-log-configuration/incident-log-configuration-update-paper-consignment-configuration';

@Injectable({ providedIn: 'root' })
export class ApiIncidentLogConfigurationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `incidentLogConfigurationGetConfiguration()` */
  static readonly IncidentLogConfigurationGetConfigurationPath = '/incident-log/configuration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `incidentLogConfigurationGetConfiguration()` instead.
   *
   * This method doesn't expect any request body.
   */
  incidentLogConfigurationGetConfiguration$Response(params?: IncidentLogConfigurationGetConfiguration$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<IncidentLogConfigurationDto>> {
    return incidentLogConfigurationGetConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `incidentLogConfigurationGetConfiguration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  incidentLogConfigurationGetConfiguration(params?: IncidentLogConfigurationGetConfiguration$Params|null|undefined, context?: HttpContext): Observable<IncidentLogConfigurationDto> {
    return this.incidentLogConfigurationGetConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<IncidentLogConfigurationDto>): IncidentLogConfigurationDto => r.body)
    );
  }

  /** Path part for operation `incidentLogConfigurationUpdatePaperConsignmentConfiguration()` */
  static readonly IncidentLogConfigurationUpdatePaperConsignmentConfigurationPath = '/incident-log/configuration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `incidentLogConfigurationUpdatePaperConsignmentConfiguration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  incidentLogConfigurationUpdatePaperConsignmentConfiguration$Response(params: IncidentLogConfigurationUpdatePaperConsignmentConfiguration$Params, context?: HttpContext): Observable<StrictHttpResponse<IncidentLogConfigurationDto>> {
    return incidentLogConfigurationUpdatePaperConsignmentConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `incidentLogConfigurationUpdatePaperConsignmentConfiguration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  incidentLogConfigurationUpdatePaperConsignmentConfiguration(params: IncidentLogConfigurationUpdatePaperConsignmentConfiguration$Params, context?: HttpContext): Observable<IncidentLogConfigurationDto> {
    return this.incidentLogConfigurationUpdatePaperConsignmentConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<IncidentLogConfigurationDto>): IncidentLogConfigurationDto => r.body)
    );
  }

  /** Path part for operation `incidentLogConfigurationCreateConfiguration()` */
  static readonly IncidentLogConfigurationCreateConfigurationPath = '/incident-log/configuration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `incidentLogConfigurationCreateConfiguration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  incidentLogConfigurationCreateConfiguration$Response(params: IncidentLogConfigurationCreateConfiguration$Params, context?: HttpContext): Observable<StrictHttpResponse<IncidentLogConfigurationDto>> {
    return incidentLogConfigurationCreateConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `incidentLogConfigurationCreateConfiguration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  incidentLogConfigurationCreateConfiguration(params: IncidentLogConfigurationCreateConfiguration$Params, context?: HttpContext): Observable<IncidentLogConfigurationDto> {
    return this.incidentLogConfigurationCreateConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<IncidentLogConfigurationDto>): IncidentLogConfigurationDto => r.body)
    );
  }

}
